@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@import 'react-toastify/dist/ReactToastify.css';

@font-face {
  font-family: 'Aeonik Bold';
  src: url('assets/fonts/Aeonik-Bold.otf');
}
@font-face {
  font-family: 'Aeonik Medium';
  src: url('assets/fonts/Aeonik-Medium.otf');
}
@font-face {
  font-family: 'Aeonik Regular';
  src: url('assets/fonts/Aeonik-Regular.otf');
}
@font-face {
  font-family: 'Aeonik Light';
  src: url('assets/fonts/Aeonik-Light.otf');
}
@font-face {
  font-family: 'Aeonik Thin';
  src: url('assets/fonts/Aeonik-Thin.otf');
}

body {
  margin: 0;
  font-family: 'Aeonik Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}

.font-medium {
  font-family: 'Aeonik Medium', sans-serif;
}

.font-bold {
  font-family: 'Aeonik Bold', sans-serif;
}

.font-normal {
  font-family: 'Aeonik Regular', sans-serif;
}

.font-light {
  font-family: 'Aeonik Light', sans-serif;
}

.font-thin {
  font-family: 'Aeonik Thin', sans-serif;
}

.ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  color: #2b2b33;
  font-family: 'Aeonik Regular';
}

.Toastify__progress-bar--error {
  background-color: #000000;
}
